import styled from "@emotion/styled";
import { darkblue } from '../colors';

const Label = styled.label`
    display: block;
    position: relative;
    z-index: 0;
    width:  ${props => props.big ? '25%' : '100%'};
    color: ${props => props.color ? props.color : darkblue};
    padding: ${props => props.noMargin ? '5px 0px;' : '10px 0px;'}
    transition: 0.15s ease-in-out;
`;

export default Label;
