import tokenStorage from "./TokenStorage";

export function isDeepsightAdmin() {
  return isGranted('ROLE_DEEPSIGHT_ADMIN');
}

export function isSuperAdmin() {
  return isGranted('ROLE_SUPER_ADMIN');
}

// atm this should not be used
export function isAdmin() {
  return isGranted('ROLE_ADMIN');
}

function isGranted(role) {
  const token = tokenStorage.getData();

  // console.log(token);

  if (token === null) {
    return false;
  }

  // console.log(token.roles);

  if (Array.isArray(token.roles) === false) {
    return false;
  }

  return token.roles.includes(role);
}
