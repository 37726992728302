import styled from '@emotion/styled';
import { errorred20, errorred } from '../colors';
import React from 'react';
import Icon from './Icon/Icon';

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  color: ${errorred};
  background: ${errorred20};
`;

const IconContainer = styled.div`
  color: ${errorred};
  background: ${errorred20};
  margin-right: 15px;
`;
const MessageContainer = styled.div`
  display: flex;
  text-align: left;
  color: ${errorred};
  background: ${errorred20};
  
  ${props => props.big &&`
      font-size: 32px;
  `}
`;

export default class Error extends React.PureComponent {
  render() {
    const { children, big} = this.props;

    return (
      <ErrorContainer>
        <IconContainer>
          <Icon name="caution_error" size={big ? 48 : 32}/>
        </IconContainer>
        <MessageContainer big={big}>
          {children}
        </MessageContainer>
      </ErrorContainer>
    );
  }
}
