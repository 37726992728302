import React from 'react';
import ErrorMessage from './ErrorMessage';
import styled from '@emotion/styled';
import Label from './Label';
import {red, grey70, grey60, grey30, grey10} from '../colors';
import Icon from "./Icon/Icon";

const Container = styled.div`
    width: 100%;
`;

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content:  flex-end;
`;

const EyeIcon = styled(Icon)`
    max-width: 26px;
    position: relative;
    top: -38px;
    right: 15px;
    z-index: 1;
    cursor: pointer;
    color: #f7f7f7;
    flex: 1;
    fill: ${grey60};
`;

const InputField = styled.input`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    padding: 10px 10px;
    appearance: none;
    border: none;
    ${props => props.bordered && `border: 1px solid ${grey10};`}
    display: block;
    outline: 0 none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
    &:disabled {
      color: ${grey70};
      background: none;
      font-family: AvenirLTPro-Demi, AvenirNextCyrMedium;
      border: 1px solid ${grey30};
    }

    ${props => props.error && `border: 1px solid ${red};`}
`;

class Input extends React.Component {
  state = {
    focus: false,
  };

  valueToForm(value) {
    if (value === null) {
      return '';
    }

    return value;
  }

  formToValue(value) {
    const {type} = this.props;

    if (value === '') {
      return null;
    }

    if (type === 'number') {
      return Number(value);
    }

    return value;
  }

  onChange(event) {
    const {onChange, type} = this.props;
    const {value, maxLength} = event.target;

    if (type === 'file') {
      onChange(event);
      return;
    }

    let formValue = value;

    if (maxLength && maxLength !== -1) {
      formValue = formValue.slice(0, maxLength);
    }

    onChange(this.formToValue(formValue));
  };

  render() {
    const {
      id,
      type,
      name,
      value,
      readOnly,
      disabled,
      className,
      error,
      placeholder = '',
      min = null,
      max = null,
      maxLength = null,
      filename = null,
      icon,
      iconClick,
      bordered
    } = this.props;

    return (
      <Container>
        <React.Fragment>
          {this.renderLabel()}
          <InputField
            data-cy={id}
            id={id}
            name={name}
            className={className}
            type={type || 'text'}
            value={this.valueToForm(value)}
            filename={filename}
            onChange={event => this.onChange(event)}
            readOnly={readOnly}
            disabled={disabled}
            onBlur={() => this.setState({focus: false})}
            onFocus={() => this.setState({focus: true})}
            placeholder={placeholder}
            min={min}
            max={max}
            maxLength={maxLength}
            error={error}
            bordered={bordered}
          />
          <IconContainer>
            {icon &&
            <EyeIcon
              onClick={iconClick} size={26}
              name={icon}/>
            }
          </IconContainer>
          {error && <ErrorMessage id={id} error={error}/>}
        </React.Fragment>
      </Container>
    );
  }

  renderLabel() {
    const {value, label, id} = this.props;
    const {focus} = this.state;

    return (
      <Label htmlFor={id} focusx={true} focus={focus || value !== null}>
        {label}
      </Label>
    );
  }
}

export default Input;
