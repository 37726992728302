import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const EVENT_LOGIN = 'LOGIN';
export const EVENT_LOGGED_OUT = 'LOGGED_OUT';
export const EVENT_EXPIRED = 'EXPIRED';

class TokenStorage {
  constructor() {
    this.handlers = [];

    const token = this.getToken();

    if (!token) {
      return;
    }

    this.startTimeout(token);
  }

  getToken() {
    const token = localStorage.getItem('token');

    if (!token) {
      return null;
    }

    if (this.checkExpired(token)) {
      this.expired();

      return null;
    }

    return token;
  }

  getData() {
    const token = this.getToken();

    if (!token) {
      return null;
    }

    return jwtDecode(token);
  }

  expired() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    localStorage.removeItem('token');

    this.fire(EVENT_EXPIRED);
  }

  logout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    localStorage.removeItem('token');

    this.fire(EVENT_LOGGED_OUT);
  }

  setToken(token) {
    localStorage.setItem('token', token);
    this.startTimeout(token);

    this.fire(EVENT_LOGIN);
  }

  startTimeout(token) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(
      () => {
        this.expired()
      },
      this.secondsUntilExpired(token)
    );
  }

  subscribe(handler) {
    this.handlers.push(handler)
  }

  unsubscribe(handlerToBeRemoved) {
    let currentHandlers = this.handlers;

    this.handlers = currentHandlers.filter((handler) => {
      return handler !== handlerToBeRemoved
    })
  }

  fire(event) {
    for (const handler of this.handlers) {
      handler(event);
    }
  }

  secondsUntilExpired(token) {
    const data = jwtDecode(token);
    const seconds = moment.unix(data.exp).diff(moment());

    if (seconds < 0) {
      return 0;
    }

    return seconds;
  }

  checkExpired(token) {
    return this.secondsUntilExpired(token) === 0;
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
