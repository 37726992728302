import styled from "@emotion/styled";
import { grey80, white } from "../colors";

const CardHeaderBackground = styled.div`
  padding: 20px 42px 0 42px;
  margin: -80px -42px 0 -42px;
  background: ${grey80};
`;

export const CardHeaderBackgroundHeadline = styled.div`
  display: flex;
  flow-direction: row;
  align-items: center;
  padding: 0 70px 20px 70px;
  color: ${white};
`;

export const HeadlineIcon = styled.div`
  margin-right: 20px;
`;

export const HeadlineText = styled.div`
  font-size: 18px;
`;

export default CardHeaderBackground;
