function error(code, t) {
  switch (code) {
    case 0:
      return t("error0", 'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support');
    case 101:
      return t("error101", "Es sind keine gültigen Ergebnisse verfügbar. Bitte erstatten Sie Ihre Kosten und wenden Sie sich an den Support. ")
    case 102:
      return t("error102", "Es sind keine gültigen Ergebnisse verfügbar, bitte kontaktieren Sie den Support.")
    case 111:
      return t("error111", "Die Organisation konnte nicht erstellt werden. Bitte wenden Sie sich an den Support.");
    case 124:
      return t("error124", "Die Kostennummer in dieser Organisation ist ungültig.");
    case 167:
      return t("error167", "Die Kostennummer ist in dieser Organisation schon vergeben.");
    case 188:
      return t("error188", "Die Kostennummer wurde nicht gefunden");
    case 211:
      return t("error211", "Die E-Mail konnte nicht gesendet werden");
    case 221:
      return t("error221", "Die Bonitätshistorie wurde nicht gefunden");
    case 231:
      return t("error231", "Keine Organisation mit dieser Bezeichnung gefunden");
    case 305:
      return t("error305", "Bitte geben Sie eine Organisation an");
    case 306:
      return t("error306", "Dieser Datei-Typ ist nicht für die Studie zugelassen. Bitte laden Sie .xls, xlsx, .csv .tsv, .txt, .docx oder .zip Datei hoch");
    case 307:
      return t("error307", "Dieser Datei-Typ ist nicht für die Studie zugelassen.");
    case 308:
      return t("error308", "Bitte geben Sie einen Token an");
    case 309:
      return t("error309", "Ungültige Angabe Token");
    case 310:
      return t("error310", "Bitte geben Sie eine Studie an");
    case 311:
      return t("error311", "Ungültige Angabe Studie");
    case 312:
      return t("error312", "Bitte geben Sie eine Datei an");
    case 313:
      return t("error313", "Ungültige Angabe Datei");
    case 314:
      return t("error314", "Ungültige Angabe Sprache");
    case 315:
      return t("error315", "Bitte geben Sie ein gültiges Passwort an");
    case 316:
      return t("error316", "Die Passwörter stimmen nicht überein");
    case 317:
      return t("error317", "Bitte geben Sie einen Auftrag an");
    case 318:
      return t("error318", "Ungültige Angabe Auftrag");
    case 319:
      return t("error319", "Ungültige Angabe");
    case 320:
      return t("error320", "Ungültige Angabe");
    case 321:
      return t("error321", "Ungültige Angabe");
    case 322:
      return t("error322", "Bitte geben Sie eine gültige E-Mail Adresse an");
    case 323:
      return t("error323", "Bitte geben Sie ein Projekt an");
    case 324:
      return t("error324", "Ungültige Angabe Projekt");
    case 325:
      return t("error325", "Ungültige Angabe Notiz");
    case 326:
      return t("error326", "Bitte geben Sie einen Namen an");
    case 327:
      return t("error327", "Ungültige Angabe Name");
    case 328:
      return t("error328", "Bitte geben Sie einen Projektverantwortlichen an");
    case 329:
      return t("error329", "Bitte geben Sie eine Kostennummer an");
    case 330:
      return t("error330", "Ungültige Angabe Kostennummer");
    case 331:
      return t("error331", "Bitte geben Sie ein Packet an");
    case 332:
      return t("error332", "Ungültige Angabe Packet");
    case 333:
      return t("error333", 'Sie haben keinen Zugriff auf die Studienbearbeitung');
    case 334:
      return t("error334", "Ungültige Angabe Projektverantwortlicher");
    case 335:
      return t("error335", "Ungültige Angabe");
    case 336:
      return t("error336", "Ungültige Angabe");
    case 337:
      return t("error337", "Ungültige Angabe Vorname");
    case 338:
      return t("error338", "Ungültige Angabe Nachname");
    case 339:
      return t("error339", "Ungültige Angabe Status");
    case 340:
      return t("error340", "Das Passwort muss Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten.");
    case 341:
      return t("error341", "Das Passwort muss mindestens 12 Zeichen beinhalten.");
    case 342:
      return t("error342", "Ungültige Angabe Organisation Name");
    case 343:
      return t("error343", "Bitte laden Sie eine Datei hoch");
    case 375:
      return t("error375", "Der Token ist bereits ungültig");
    case 430:
      return t("error430", "Die UserId ist ungültig.");
    case 433:
      return t("error433", "Paket wurde nicht gefunden");
    case 444:
      return t("error444", 'Die Studie kann nicht erstattet werden ');
    case 451:
      return t("error451", 'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support');
    case 454:
      return t("error454", 'Ungueltige Angabe IP');
    case 488:
      return t("error488", 'Der Projektname ist in dieser Organisation schon vergeben.');
    case 492:
      return t("error492", 'Die E-Mail-Adresse wird bereits verwendet.');
    case 523:
      return t("error523", 'Die Studie ist nicht automatisiert');
    case 553:
      return t("error553", "Kein Projekt mit einem solchen Namen gefunden");
    case 555:
      return t("error555", "Kein Log ist gefunden");
    case 556:
      return t("error556", "Nicht genügend Credits");
    case 583:
      return t("error583", "Das Projekt hat noch keine Kostennummer zugewiesen.");
    case 646:
      return t("error646", "Sie haben noch laufende Studien, darum kann Ihr Konto nicht gelöscht werden");
    case 666:
      return t("error666", "Das Projekt existiert nicht");
    case 667:
      return t("error667", "Das Studie existiert nicht");
    case 668:
      return t("error668", "Die Datei existieren nicht");
    case 669:
      return t("error669", "Kein Administrator mit dieser E-Mail Adresse gefunden");
    case 670:
      return t("error670", "Kein Administrator mit dieser ID gefunden");
    case 671:
      return t("error671", "Admin mit dieser ID existiert bereits");
    case 677:
      return t("error677", "Sie haben keine Rechte zum Ausführen des Vorgangs");
    case 721:
      return t("error721", "Das Projekt ist aktuell nicht löschbar.");
    case 753:
      return t('error753', "Das ausgewählte Paket ist ungültig.");
    case 759:
      return t("error759", "Die Studie ist aktuell nicht löschbar.");
    case 760:
      return t("error760", "Sie haben keinen Zugriff auf diese Organisation");
    case 764:
      return t("error764", "Bitte geben Sie vor dem Kauf die Details Ihrer Organisation vollständig ein.")
    case 765:
      return t("error765", "Bitte, uberpuefen die angegeben Parametres");
    case 723:
      return t("error723", "Sie haben keine Rechte das Projekt zu editieren");
    case 791:
      return t("error791", "Es konnte kein Projekt gefunden werden");
    case 803:
      return t("error803", "Sie haben keine zugriffsrechte auf diese Studie");
    case 812:
      return t("error812", "Die Datei ist aktuell nicht löschbar.");
    case 866:
      return t("error866", "Die Studie steht nicht in der Warteschlange");
    case 875:
      return t("error875", "Die Studie kann nicht mehr bearbeitet werden");
    case 888:
      return t("error888", "Sie haben nicht ausreichend Credits um die Analyse zu beauftragen.");
    case 890:
      return t("error890", "Die Studie kann nicht gelöscht werden");
    case 903:
      return t("error903", "Der Token ist nicht gültig");
    case 954:
      return t("error954", "Der Benutzer wurde nicht gefunden");
    case 964:
      return t("error964", "Der Preis konnte nicht berechnet werden.");
    case 987:
      return t("error987", "Sie sind der Organisationsleiter");
    case 993:
      return t("error993", "Einer Benutzer mit dieser E-Mail Adresse ist bereits vorhanden");
    case 1000:
      return t("error1000", "Deine Organisation hat noch positives Credits, darum kann Ihr Konto nicht gelöscht werden");
    case 1001:
      return t("error1001", "Sie besitzen noch Projekte, darum Ihr Konto kann nicht gelöscht werden");
    case 1002:
      return t("error1002", "Eine Organisation mit dieser Bezeichnung ist bereits vorhanden");
    case 1003:
      return t("error1003", "Only organisation admin can delete the organisation");
    case 1004:
      return t("error1004", "Deletion request has been sent to Deepsight admin");
    default:
      return t("errorDefault", 'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support');
  }
}

export default error;
