import {Redirect} from "react-router-dom";
import React from "react";
import tokenStorage from "./TokenStorage";

function withRole(Component, role, currentStep=null, closeTour=null, tourConfig=null,
                  isTourOpen=null, setTourPassed=null, nextStepClicked=null,
                  isProjectSubmitted=null, setIsProjectSubmitted=null) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        data: tokenStorage.getData(),
      };
    }

    updateToken = () => {
      this.setState({
        data: tokenStorage.getData(),
      });
    };

    componentDidMount() {
      tokenStorage.subscribe(this.updateToken);
    }

    componentWillUnmount() {
      tokenStorage.unsubscribe(this.updateToken);
    }

    render() {
      const { props } = this.props;
      const { data } = this.state;

      if (!data) {
        return <Redirect to="/" />
      }

      if (data.roles.includes(role) === false) {
        return <Redirect to="/pages/NotFound" />
      }

      return <Component {...props} token={data} currentStep={currentStep} closeTour={closeTour} tourConfig={tourConfig}
                        isTourOpen={isTourOpen} setTourPassed={setTourPassed} nextStepClicked={nextStepClicked}
                                   isProjectSubmitted={isProjectSubmitted} setIsProjectSubmitted={setIsProjectSubmitted}
      />;
    }
  };
}

export default withRole;
