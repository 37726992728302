import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';
import publicIp from "public-ip";

import {post} from '../security/Api';
import TextInputLiveFeedback from '../components/TextInputLiveFeedback';
import {Button} from '../components/ButtonReg';
import PageContent from '../components/PageContent';
import {Formik, ErrorMessage, Field} from 'formik';
import * as Yup from "yup";
import CardBody from '../components/CardBody';
import {Form} from '../components/FormReg';
// import {AnonymPageHeadline, AnonymPageInfo, LoginButtonContainer} from './Login';

import Page from '../components/Page';
import {maingrey, white} from '../colors';
import {withTranslation} from "react-i18next";
import Alert from '../components/Alert';

import error from "../error";
import Grid from "@mui/material/Grid";
import {LinkButton} from "../components/Button";
import {HeadlineText} from "../components/CardHeaderBackground";

export const AnonymPageHeadline = styled(HeadlineText)`
    font-size: 40px;
    margin-top: -25px;
    padding-bottom: 35px;
    font-weight: bolder;
`;

export const LoginButtonContainer = styled.div`
    // margin-top: 20px;
`;

export const AnonymPageInfo = styled(HeadlineText)`
    font-size: 24px;
`;

const CheckBoxField = styled(Field)`
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 25px;
    height: 25px;
    position: relative;
    top: 7px;
`;

const DatenschutzLink = styled(Link)`
  margin-right: 6px;
  margin-left: 6px;
  color: #7c7c8c;
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: inline;

  &:hover {
    color: #7e8c96;
  }
`;

const LinkButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    padding-top: 15px;
    margin-bottom: 50px;
`;

const AgbContainer = styled.div`
    // display: flex;
    // justify-content: left;
    padding-top: 15px;
    margin-bottom: 10px;
`;

class Registration extends React.PureComponent {
  state = {
    error: false,
    errorMessage: null,
    ip: '',
    privacyPolicyConfirmed: false,
    passwordFieldType: "password",
    confirmPasswordFieldType: "password"
  };

  componentDidMount = async () => {
    let currentIp = await publicIp.v4({});
    await this.setState({ip: currentIp});
  }

  onSignUpSuccess = () => {
    this.setState({
      error: false,
      errorMessage: null
    });
    this.props.history.push('/registration/success');
    return;
  }
  onSignUpError = async (code, setFieldError) => {
    if (code === 492) { //the email is in use
      await setFieldError('email', error(code, this.props.t));
    } else {
      await this.setState({
        error: true,
        errorMessage: error(code, this.props.t)
      })
    }
  }
  pageReload = () => {
    this.setState({
      error: false,
      errorMessage: null,
    })
  }

  togglePrivacyPolicy = async (prop) => {

    await this.setState({
      privacyPolicyConfirmed: {
        ...this.state.privacyPolicyConfirmed,
        privacyPolicyConfirmed: !this.state.privacyPolicyConfirmed
      }
    });
  }

  showHidePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  renderLeftHeader() {
    const {t} = this.props;
    return (
      <Grid item xs={1} md={1} key="2">
        <CardBody background={maingrey}>
            <AnonymPageHeadline>{t('registr49', "Jetzt registrieren")}</AnonymPageHeadline>
            <AnonymPageInfo>
              <div>1. {t('registr50', "Kostenfrei Account anlegen")}</div>
              <div>2. {t('registr51', "Data Services testen")}</div>
              <div> 3. {t('registr52', "Credits aufladen")}</div>
            </AnonymPageInfo>
        </CardBody>
      </Grid>
    );
  }

  renderForm() {
    const {t} = this.props;
    const messageDontMatch = t("registr43", "Das Passwort muss Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten");
    const SignupSchema = Yup.object().shape({
      email: Yup
        .string(t("registr34", "Ungültige Angabe E-Mail"))
        .email(t("registr35", "Ungültige Angabe E-Mail"))
        .required(t("registr36", "Dieses Feld muss ausgefüllt sein.")),
      password: Yup
        .string(t("registr56", "Ungültige Angabe Passwort"))
        .min(12, t("registr42", "Das Passwort muss mindestens 12 Zeichen beinhalten"))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{0,}/, {
          message: messageDontMatch,
          excludeEmptyString: true
        })
        .required(t("registr44", "Dieses Feld muss ausgefüllt sein.")),
      passwordSecond: Yup
        .string(t("registr57", "Ungültige Angabe Passwort"))
        .required(t("registr47", "Dieses Feld muss ausgefüllt sein."))
        .test('match', t("registr46", "Die Passwörter stimmen nicht überein"), function (passwordSecond) {
          return passwordSecond === this.options.from[0].value.password
        }),
      organisation: Yup
        .string(t("dataprivacy1", "Ungültige Angabe Organisation"))
        .required(t("registr47", "Dieses Feld muss ausgefüllt sein.")),
      acceptTerms: Yup
        .bool().oneOf([true], t("privacypolicyrequired", 'Die Bestätigung der Datenschutzbestimmungen ist notwendig.'))
    })

    return (
      <Grid item xs={1} md={1} key="3">
        <CardBody
          background={white}>
          {/*<CardBodyInnerContainer anonym={true}>*/}
          {/*  <CardBodyInner>*/}
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  passwordSecond: '',
                  organisation: '',
                  ip: this.state.ip,
                  acceptTerms: this.state.privacyPolicyConfirmed,
                }}
                validationSchema={SignupSchema}
                validateOnBlur
                onSubmit={async (values, {setFieldError}) => {
                  values.ip = this.state.ip;
                  values.locale = this.props.i18n.language;
                  await post(
                    '/registration',
                    values,
                    (code) => this.onSignUpError(code, setFieldError),
                    this.onSignUpSuccess
                  );
                }}
              >
                <Form>
                  <TextInputLiveFeedback
                    bordered
                    id="email"
                    name="email"
                    type="text"
                    label="E-Mail *"
                  />
                  <TextInputLiveFeedback
                    bordered
                    id="password"
                    name="password"
                    type={this.state.passwordFieldType}
                    helpText={t("registr48", "Das Passwort muss aus mindestens 12 Zeichen bestehen, Groß- und Kleinschreibung, Zahlen und Sonderzeichen enthalten")}
                    label={t('registr15', "Passwort *")}
                    icon={"eye"}
                    iconClick={() => {
                      this.setState({
                        passwordFieldType: this.state.passwordFieldType === 'input' ? 'password' : 'input'
                      })
                    }}
                  />
                  <TextInputLiveFeedback
                    bordered
                    id="passwordSecond"
                    name="passwordSecond"
                    type={this.state.confirmPasswordFieldType}
                    label={t('registr16', "Passwort wiederholen *")}
                    icon={"eye"}
                    iconClick={() => {
                      this.setState({
                        confirmPasswordFieldType: this.state.confirmPasswordFieldType === 'input' ? 'password' : 'input'
                      })
                    }}
                  />
                  <TextInputLiveFeedback
                    bordered
                    id="organisation"
                    name="organisation"
                    type="text"
                    label={t('registr54', "Organisation *")}
                  />

                  <AgbContainer>
                  <span>
                      <CheckBoxField
                        id="acceptTerms"
                        name="acceptTerms"
                        type="checkbox"
                      />
                    {t("dataprivacy2", "Ich bestätige hiermit die ")}
                    <DatenschutzLink to={'/data-protection'}
                                     target="_blank">{t("privacypolicy", "Datenschutzbestimmungen. ")}</DatenschutzLink>
                  </span>

                    <ErrorMessage name="acceptTerms">
                      {msg => <div style={{color: 'red'}}>{msg}</div>}
                    </ErrorMessage>

                    <br/>
                    <br/>

                    <span>
                    {t("agbs1", "Mit der Erstellung eines Accounts bestätigen Sie unsere")}
                      <DatenschutzLink
                        target="_blank"
                        to={'/agb'}>
                        {t("agbs", "AGB")}
                      </DatenschutzLink>
                    </span>
                  </AgbContainer>

                  <LoginButtonContainer>
                    <Button
                      mainbutton
                      texttransformnone
                      minwidth
                      tiny
                      rounded
                      data-cy="register-submit"
                      // icon={'main_arrow'}
                      id="submit"
                      type="submit">
                      {t('registr17', "Account anlegen")}
                    </Button>
                  </LoginButtonContainer>

                  {/*{t("account30", "Rechnungsadresse")}*/}
                </Form>
              </Formik>
              {t('registr530', "Du hast schon einen Account? Dann kannst du dich")} &nbsp;

              <LinkButtonContainer>
                <LinkButton
                  mainoutlined
                  texttransformnone
                  minwidth
                  tiny
                  rounded
                  outline="true"
                  data-cy="link-to-registration"
                  // icon="main_arrow"
                  to={'/login'}>
                  {t('login8', "Anmelden")}
                </LinkButton>
              </LinkButtonContainer>

        </CardBody>
      </Grid>
    );
  }

  render() {
    const {t} = this.props;

    return (
      <Page background={maingrey}>
        <PageContent middle>
          <Grid container spacing={{xs: 1, md: 2}} columns={{xs: 1, md: 2}} sx={{backgroundColor: "#efefef"}}>
            {this.renderLeftHeader()}
            {this.renderForm()}
          </Grid>

        </PageContent>
        {this.state.error &&
        <Alert
          type="error"
          message={this.state.errorMessage ? this.state.errorMessage : error(0, t)}
          onClose={this.pageReload}
        />
        }
      </Page>
    );
  }
}

export default withTranslation("translations")(Registration);
