import React, {Component} from 'react';
// import styled from '@emotion/styled';
import ISO6391 from 'iso-639-1'; //language names and codes
import ButtonLink from './Buttons/ButtonLink';

import {withTranslation} from "react-i18next";

import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/menu.min.css';

// import {Button} from 'semantic-ui-react';
import AriaMenuButton from 'react-aria-menubutton';
import FlagDE from '../components/Flag/germany.png';
import FlagGB from '../components/Flag/united-kingdom.png';
import FlagRU from '../components/Flag/russia.png';
import FlagNL from '../components/Flag/netherlands.png';
import FlagFR from '../components/Flag/france.png';


class LanguageCodePicker extends Component {

  state = {
    language: this.props.i18n.language,
    languages: ["en", "de", "fr", "nl", "ru"],
  };


  getLanguageName = (code) => {
    if (!code) return '';

    // also returns empty string if unknown
    return (
      ISO6391.getName(code.substring(0, 2).toLowerCase())
    )
  };

  getLanguageFlag = (code) => {
    return (
      <img
        src={code === 'fr' ? FlagFR : code === 'ru' ? FlagRU : code === 'en' ? FlagGB : code === 'nl' ? FlagNL : FlagDE}
        alt="Flag"
        width={29}
        height={29}
        style={{
          'position': 'relative',
          'marginRight': '10px',
          'top': '8px'
        }}
      />
    )
  }

  getCurrentLanguageFlag = (code) => {
    return (
      <img
        src={code === 'fr' ? FlagFR : code === 'ru' ? FlagRU : code === 'en' ? FlagGB : code === 'nl' ? FlagNL : FlagDE}
        alt="Flag"
        width={30}
        height={30}
        style={{
          // 'top': '3px'
        }}
      />
    )
  }

  getLanguageOptionsOld = () => {
    return this.state.languages.map((lang, i) => {
      //let flag = flagForLocale(locale) || 'icon';
      if (lang !== this.state.language) {
        return <AriaMenuButton.MenuItem
          style={{
            'text-align': 'left',
            'padding': '3px 0 3px 15px',
            'marginBottom': '14px',
            'marginTop': '0'
          }}
          className='item'
          value={lang}
          key={i}
          tag='li'
          text={this.getLanguageName(lang)}>
          <span>
            {this.getLanguageFlag(lang)}
            {this.getLanguageName(lang)}
          </span>

        </AriaMenuButton.MenuItem>;
      } else {
        return ""
      }

    });
  }

  handleChange = (lang) => {
    if (lang === this.state.language) return;
    this.setState(prevState => ({language: lang}));
    this.props.i18n.changeLanguage(lang);
  };

  render() {

    return (

      <>
        <AriaMenuButton.Wrapper onSelection={this.handleChange}
                                style={{
                                  'cursor': 'pointer',
                                  'display': 'block',
                                  // 'marginRight': '80px',
                                  'lineHeight': '22px',
                                }}>
          <AriaMenuButton.Button>
            <ButtonLink
              small
              outline
              right
              icon={"main_arrow"}
              iconsize={25}
              iconcolor={"blue"}
              text={this.getLanguageName(this.props.i18n.language)}
            />
            {/*<Button*/}
            {/*  basic*/}
            {/*  icon*/}
            {/*  secondary*/}
            {/*  circular*/}
            {/*  animated*/}
            {/*  style={{*/}
            {/*    'fontSize': '0.08em',*/}
            {/*    'boxShadow': '0 !important',*/}
            {/*  }}>*/}
            {/*  {this.getCurrentLanguageFlag(this.props.i18n.language)}*/}
            {/*</Button>*/}
          </AriaMenuButton.Button>
          <AriaMenuButton.Menu className='ui menu vertical locale-switcher'
                               style={{
                                 'width': '10rem',
                                 'position': 'fixed',
                                 'right': '20px',
                                 'bottom': '16px',
                                 'zIndex': '3',
                                 'display': 'flex !important',
                                 'fontFamily': 'AvenirLTPro-Black, AvenirNextCyrBold',
                                 'fontSize': '16px',
                                 'borderRadius': '0',
                               }}>
            {this.getLanguageOptionsOld()}
          </AriaMenuButton.Menu>
        </AriaMenuButton.Wrapper>
      </>

    )
  }
}


export default withTranslation("translations")(LanguageCodePicker);
