import styled from "@emotion/styled";
import { Link as BaseLink } from "react-router-dom";
import {blue, bluedarker} from "../colors";


const Link = styled(BaseLink)`
  color: ${blue};
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${bluedarker};
  }
`;

export default Link;
