import styled from "@emotion/styled";
import { darkblue } from '../colors';

const Label = styled.label`
    display: block;
    position: relative;
    z-index: 0;
    float:left;
    padding: ${props => props.narrow ? "5px 0" : "10px 0px"};
    margin-top: ${props => props.narrow ? "10px" : "20px"};
    transition: 0.15s ease-in-out;
    color: ${darkblue};
`

export default Label;
