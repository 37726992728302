import React from 'react';
import styled from '@emotion/styled';
import {bluedarker, darkblue, grey30, white} from '../../colors';
import Icon from '../Icon/Icon';
import {withTranslation} from "react-i18next";

const ButtonContainer = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // flex-direction: row-reverse;
  background-color: inherit;
  height: ${props => props.small ? '54px' : props.tiny ? '32px' : '56px'};
  padding: ${props => props.text || props.tiny ? '16px 1px 18px 24px' : '11px'};
  ${props => !props.text && 'width: 54px;'};
  line-height: ${props => props.small ? '54px' : props.tiny ? '32px' : '56px'};
  color: ${props => props.outline ? darkblue : white};
  border: 0;
  cursor: pointer;
  
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
  font-size: 15px; 
  
  text-decoration: none;
  text-align: left;

  ${props => !props.text && 'width: 54px;'};

  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  ${props => !props.disabled && !props.delete && !props.danger && `
  &:hover {
    color: ${grey30};
    // background: ${bluedarker};
    // border-color ${bluedarker};
  };
  `};

  > svg {
    ${props => props.text && props.icon && 'margin-right: 16px;'}
  }
`;

export const ButtonText = styled.div`
  margin-bottom: -4px;
  line-height: 20px;
  padding-right: 10px;
`;

function ButtonLink(props) {

  const {right, type} = props;

  return (
    <ButtonContainer
      {...props}
      text={props.text !== undefined ? "true" : undefined}
      right={right}
      type={type}>

      {props.icon && !right && <Icon name={props.icon}/>}

      <ButtonText>
        {props.text}
      </ButtonText>

      {props.icon && right && <Icon style={{fill: "#FFFFFF"}} name={props.icon} size={props.iconsize}/>}


    </ButtonContainer>
  );
}
export default withTranslation("translations")(ButtonLink);
