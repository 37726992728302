import React, {Component} from 'react';

import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import {maingrey, white} from '../colors';

import tokenStorage from '../security/TokenStorage';
import {login} from '../security/Api';
import {isDeepsightAdmin} from '../security/Role';

import Input from '../components/Input';
import {Button, LinkButton} from '../components/Button';
import PageContent from '../components/PageContent';
import Link from '../components/Link';
import CardBody from '../components/CardBody';
import ErrorMessage from '../components/Error';
import {Form} from '../components/Form';
import {HeadlineText} from '../components/CardHeaderBackground';
import Page from '../components/Page';

import {withTranslation} from "react-i18next";

var controller = new AbortController();
var signal = controller.signal;

export const AnonymPageHeadline = styled(HeadlineText)`
    font-size: 40px;
    margin-top: -25px;
    padding-bottom: 35px;
    font-weight: bolder;
`;

export const AnonymPageInfo = styled(HeadlineText)`
    font-size: 24px;
`;

export const AnonymPageRegisterQuestion = styled(HeadlineText)`
    font-size: 16px;
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    margin-bottom: -34px;
    > a {
        z-index: 1;
    }
`;

export const LoginButtonContainer = styled.div`
    // margin-top: 20px;
`;

const LinkButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    padding-top: 15px;
    margin-bottom: 50px;
`;

class Login extends Component {
  state = {
    email: null,
    password: null,
    error: false,
    value: "de",
    type: "password"
  };

  componentWillUnmount() {
    controller.abort();
  }

  onChange = (key, value) => {
    this.setState({[key]: value});
  };

  onSubmit = async () => {
    const {history} = this.props;
    const {email, password} = this.state;

    const response = await login(email, password, {signal});

    if (response.status === 401) {
      this.setState({
        error: true,
      });
      return;
    }
    console.log(response.status);

    if (response.status === 200) {
      this.setState({
        error: false,
      });

      const responseData = await response.json({signal});

      tokenStorage.setToken(responseData.data.token);

      if (isDeepsightAdmin()) {
        history.push('/organisation/list');
      } else {
        history.push('/project');
      }
      return;
    }
    throw new Error('wrong status code ' + response.status);
  };

  showHide = () => {
    //e.preventDefault();
    //e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  renderLeftHeader() {
    const {t} = this.props;
    return (
      <Grid item xs={1} md={1} key="2">
        <CardBody background={maingrey}>
            <AnonymPageHeadline>{t('login100', "Bei Ihrem Konto anmelden")}</AnonymPageHeadline>
        </CardBody>
      </Grid>
    );
  }

  renderForm() {
    const {email, password, error, type} = this.state;
    const {t} = this.props;
    return (
      <Grid item xs={1} md={1} key="3">
        <CardBody
          background={white}>
            {error && <ErrorMessage>
              {t('login2', "Wir konnten leider keinen Account mit diesen Zugangsdaten finden. Bitte überprüfen Sie Ihre Eingaben und probieren Sie es noch einmal.")}
            </ErrorMessage>}
            <Form>
              <Input
                bordered
                id="email"
                type="text"
                required={true}
                value={email}
                onChange={value => this.onChange('email', value)}
                label={t('login7', "E-Mail-Adresse")}
                error={error}
              />
              <LinkContainer>
                <Link to={`/password/forgot`}>
                  {t('login3', "Passwort vergessen?")}
                </Link>
              </LinkContainer>
              <Input
                bordered
                id="password"
                type={type}
                required={true}
                value={password}
                onChange={value => this.onChange('password', value)}
                label={t('login4', "Passwort")}
                error={error}
                icon={"eye"}
                iconClick={this.showHide}
              />
              {/*<Icon onClick={this.showHide} size={26} name={"pre_check"}/>*/}
              <LoginButtonContainer>
                <Button
                  mainbutton
                  texttransformnone
                  minwidth
                  tiny
                  rounded
                  data-cy="submit"
                  id="submit"
                  // icon={'main_arrow'}
                  onClick={this.onSubmit}>
                  {t("login8", "Anmelden")}
                </Button>
              </LoginButtonContainer>
            </Form>
            <br/>
            <br/>
            <AnonymPageRegisterQuestion>
              {t('login1000', "Noch keinen Account? Dann jetzt erstellen.")}
            </AnonymPageRegisterQuestion>
            <LinkButtonContainer>
              <LinkButton
                mainoutlined
                texttransformnone
                minwidth
                tiny
                rounded
                data-cy="link-to-registration"
                // icon="main_arrow"
                to={'/registration'}>
                {t('login101', "Registrieren")}
              </LinkButton>
            </LinkButtonContainer>
        </CardBody>
      </Grid>
    );
  }

  render() {
    return (
      <Page background={maingrey}>
        <PageContent middle>
          <Grid container spacing={{xs: 1, md: 2}} columns={{xs: 1, md: 2}} sx={{backgroundColor: "#efefef"}}>
            {this.renderLeftHeader()}
            {this.renderForm()}
          </Grid>
        </PageContent>
      </Page>
    );
  }
}

export default withTranslation("translations")(Login);
