import * as React from 'react';
import {Button} from './Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styled from "@emotion/styled";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";

const RightAligner = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
`;

export default function DialogResponsive(props) {

  const {isModalOpen, handleCloseDialog, startTour, skipTour, userName} = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    handleCloseDialog();
  }

  const disableBody = (target) => disableBodyScroll(target)
  const enableBody = (target) => enableBodyScroll(target)

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        afterOpen={disableBody}
        beforeClose={enableBody}
      >
        <DialogTitle id="responsive-dialog-title">
          &#127775;{"Hello, " + userName + "!"}&#127775;
        </DialogTitle>
        <DialogContent sx={{padding: "0 35px 0 35px"}}>
          <DialogContentText>
            Welcome to Data Desk, your powerful tool for
            handling data with ease! &#128640; We're thrilled to have
            you on board. To help you get started quickly, we
            offer a brief tour that introduces you to the key
            functionalities.
            <p>
            Quick Tour Highlights:
            </p>
            &#127760; Navigate seamlessly: Explore the DataDesk
            effortlessly with our user-friendly interface.

            <p>
            &#128194;Create Project: Kickstart your data journey by
            clicking "Create Project" - your first step toward
            organizing and analyzing data.
            </p>
            &#128640;Effortless Data Management: Learn how to
            import, clean, and analyze your data efficiently.
            <p>Ready to embark on this quick tour? Click "Start Tour"
            below. If you prefer to dive right in, hit "Skip Tour" -
            you can always revisit it from the menu later.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{padding: "0 0 30px 35px", justifyContent: "flex-start"}}>
          <Button
            tiny
            mainbutton
            rounded
            iconsize={25}
            children={'Start tour'}
            onClick={startTour}
          />

          <Button
            tiny
            mainbutton
            rounded
            iconsize={25}
            children={'skip tour'}
            onClick={skipTour}
          />

          <RightAligner>
            <Button
              tiny
              outline
              rounded
              iconsize={25}
              children={'not now'}
              onClick={handleCloseDialog}
            />
          </RightAligner>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
