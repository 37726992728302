import styled from "@emotion/styled";
import { grey10, grey80, white } from '../colors';

const PageContent = styled.div`
  // max-width: 2560px;
  // max-width: 2048px;
  margin:0 auto;
  padding: ${props => props.deep ? "240px 240px 240px 240px" : props => props.full ? "70px 40px 0 0" :  props => props.middle ? "170px 10% 0 10%" : "140px 42px 42px 42px"};
  ${props => props.dark && 'background: ' + grey80 + ';'}
  ${props => props.withGradient ?
  `background: transparent linear-gradient(38deg, ${grey10} 0%, ${white} 100%) 0% 0% no-repeat padding-box;` :
  props.dark ? `background:  ${grey80}` : ''};
`;

export default PageContent;
