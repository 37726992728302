
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

//Configure collecting of translations
const backendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  //
  // If allowMultiLoading is false, lngs and namespaces will have only one element each,
  // If allowMultiLoading is true, lngs and namespaces can have multiple elements
  // loadPath: '/locales/{{lng}}.json',
  //
  // // path to post missing resources
  // addPath: '/locales/add/{{lng}}.json',

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2
  // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
  // Returned JSON structure in this case is
  // {
  //  lang : {
  //   namespaceA: {},
  //   namespaceB: {},
  //   ...etc
  //  }
  // }
  //allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

  // parse data after it has been fetched
  // in example use https://www.npmjs.com/package/json5
  // here it removes the letter a from the json (bad idea)
  // parse: function(data) {
  //   //console.log(data);
  //   return data;
  // },

  //parse data before it has been sent by addPath
  //parsePayload: function(namespace, key, fallbackValue) { return { key } },

  // allow cross domain requests
  //crossDomain: false,

  // allow credentials on cross domain requests
  //withCredentials: false,

  // overrideMimeType sets request.overrideMimeType("application/json")
  //overrideMimeType: false,

  // custom request headers sets request.setRequestHeader(key, value)
  // customHeaders: {
  //   authorization: 'foo',
  //   // ...
  // },
  // can also be a function, that returns the headers
  // customHeaders: () => ({
  //   authorization: 'foo',
  //   // ...
  // }),

  // requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
  //   mode: 'cors',
  //   credentials: 'same-origin',
  //   cache: 'default'
  // }

  // define a custom request function
  // can be used to support XDomainRequest in IE 8 and 9
  //
  // 'options' will be this entire options object
  // 'url' will be passed the value of 'loadPath'
  // 'payload' will be a key:value object used when saving missing translations
  // 'callback' is a function that takes two parameters, 'err' and 'res'.
  //            'err' should be an error
  //            'res' should be an object with a 'status' property and a 'data' property containing a stringified object instance beeing the key:value translation pairs for the
  //            requested language and namespace, or null in case of an error.
  //request: function (options, url, payload, callback) {},

  // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
  // queryStringParams: { v: '1.3.5' },

  //reloadInterval: false // can be used to reload resources in a specific interval (useful in server environments)
}

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(HttpApi)
  .init({
    backend: backendOptions,
    fallbackLng: 'en',
    ns: 'translations',
    defaultNS: Array["translations"],
    fallbackNS: 'translations',
    keySeparator: false,
    debug: false,
    whitelist: ['de', 'en', 'fr', 'nl', 'ru'], // available languages for browser dector to pick from
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: false
  });


export default i18n;
