import React from 'react';
import {darkblue, errorred20, green3} from '../colors';
import styled from '@emotion/styled';
import Icon from './Icon/Icon';

const Background = styled.div`
    position: fixed;
    cursor: pointer;
    z-index: 5;
    top 75px;
    right: 75px;
    display: flex;
    flex-direction: column;
    background: ${props => props.type === 'error' ? errorred20 : green3};
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 9px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 5px 9px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    color: ${darkblue};
`;

const CloseButton = styled.button`
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
`;

const FirstLine = styled.div`
    padding-top: 5px;
    padding-right: 10px;
    align-items: center;
    text-align: right;
    margin-bottom: -10px;
`;

const InnerContainer = styled.div`
    position: relative;
    cursor: pointer;
    padding: 10px 40px 20px 40px;
    border-radius: 10px;
    font-size: 14px;
    max-width: 250px;
`;

const ContactSupport = styled.div`
    position: relative;
    cursor: pointer;
    padding: 0px 40px 25px 40px;
    border-radius: 10px;
    font-size: 14px;
    max-width: 250px;
`;

class Alert extends React.PureComponent {
  componentDidMount() {
    document.body.style.overflow = 'hidden auto';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render() {
    const {type, message, onClose} = this.props;
    return (
      <Background type={type} onClick={onClose} data-cy={`alert-${type}`}>
        <FirstLine>
          <CloseButton onClick={onClose}>
            <Icon size={10} name="close_small"/>
          </CloseButton>
        </FirstLine>
        <InnerContainer onClick={onClose}>
          {message}
        </InnerContainer>
        {type === 'error' &&
        <ContactSupport>
          support@deepsight.de
        </ContactSupport>
        }
      </Background>
    );
  }
}

export default Alert;
