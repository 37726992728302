import React from 'react';
import styled from "@emotion/styled";
import { red } from '../colors';

const ErrorMessageSpan = styled.span`
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  display: block;
  color: ${red};

  ${props => props.special && 'margin-bottom: -32px;'}
`;



function ErrorMessage({ error, id, special = false }) {
  if (!error) {
    return null;
  }

  if(Array.isArray(error)) {
    error = error[0];
  }

  return(
    <ErrorMessageSpan data-cy={"error-" + id} special={special}>{error}</ErrorMessageSpan>
  )
}

export default ErrorMessage;
