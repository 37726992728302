import React from "react";
import styled from "@emotion/styled";
import {grey10, grey5, white} from '../colors';

const CardBodyContainer = styled.div`
  background: ${props => props.background ? props.background : grey5};
  margin-bottom: ${props => props.marginBottom ? "100px" : ""};
  ${props => props.withGradient ?
    `background: transparent linear-gradient(38deg, ${grey10} 0%, ${white} 100%) 0% 0% no-repeat padding-box;` :
    `background: ${props => props.background ? props.background : grey5};`};

  border-radius: 0 0 10px 10px;

  ${props => !props.noTopPadding && !props.noBottomPadding && 'padding: 24px 42px;'};
  ${props => props.noTopPadding && !props.noBottomPadding && 'padding: 0px 42px 24px 42px;'};
  ${props => !props.noTopPadding && props.noBottomPadding && 'padding: 24px 42px 0 42px;'};

  ${props => props.noTopPadding && props.noBottomPadding && 'padding: 0 64px;'};

  ${props => props.noBottomPadding && !props.noTopPadding && `
    >:last-child {
        margin-top: -11px;
    }
  `};

  ${props => props.noBottomPadding && props.noTopPadding && `
    >:last-child {
        margin-top: -11px;
    }
  `};
  @media (max-width: 768px) {
    ${props => props.noPadding && 'padding: 0px;'};
  }
`;

export const CardBodyInner = styled.div`
  padding: 25px;
  padding: ${props => props.noTopPadding ? '0px 25px 25px 25px' : '25px'};
  ${props => props.anonym ? 'width: 50%;' : ''};
  ${props => props.home ? 'width: 30%;' : ''};
  ${props => props.forty ? 'width: 40%;' : ''};

`;

const InnerContainer = styled.div`
  display: flex;
  ${props => !props.anonym ? 'justify-content: space-between;' : ''};
`;

const CardBreak = styled.hr`
  height: 3px;
  border-width: 0;
  color: ${grey10};
  background-color: ${grey10};
  margin-top: 0;
  margin-bottom: 28px;
`;

export default class CardBody extends React.PureComponent {
    render() {
        const {children, background, withGradient, noTopPadding, noPadding, noBottomPadding, marginBottom} = this.props;

        return (
            <CardBodyContainer
              background={background}
              withGradient={withGradient}
              noTopPadding={noTopPadding}
              noBottomPadding={noBottomPadding}
              noPadding={noPadding}
              marginBottom={marginBottom}>
                {children}
            </CardBodyContainer>
        );
    }
}

export class CardBodyInnerContainer extends React.PureComponent {
    render() {
        const {children, anonym} = this.props;

        return (
            <InnerContainer anonym={anonym}>
                {children}
            </InnerContainer>
        );
    }
}

export class CardBreakLine extends React.PureComponent {
    render() {
        return (
            <CardBreak/>
        );
    }
}
