import React from 'react';
import PageContent from '../components/PageContent';
import Headline from "../components/Headline";
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";

class NotFound extends React.PureComponent {
    render() {
        const { t } = this.props;
        return (
            <Page background={grey5}>
                <PageContent>
                    <Headline>{t('notfound1',"404 Keine Seite gefunden.")}</Headline>
                </PageContent>
            </Page>
        );
    }
}

export default withTranslation("translations")(NotFound);
