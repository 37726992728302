import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from "../Icon/Icon";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {buttonmain, grey30, grey60, white} from "../../colors";

const NavLink = styled(Link)`
  color: ${grey60};
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: block;
  margin-right: 20px;
  font-size: 16px;
  line-height: 22px;
  background: transparent;
    &:hover {
    color: ${buttonmain};
  }
  
`;

const CustomMenuItem = styled(MenuItem)`
  color: ${grey60};
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: block;
  border-bottom: 2px solid ${props => props.selected ? white : 'transparent'};
  background: transparent;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    background: transparent;
    color: ${buttonmain};
  }
`;

const IconContainer = styled.div`
    color: ${props => props.inMenu ? grey30 : ''};
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
`;

export default function SubMenu(props) {

  const {t, startTourAgain} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const restartTour = () => {
    handleClose();
    startTourAgain();
  }

  return (
    <div>
      <IconContainer
        inMenu={true}
        onClick={handleClick}
      >
        <Icon size={24} name="support"/>
      </IconContainer>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >

        <CustomMenuItem onClick={restartTour}>
          <IconContainer>
            <Icon size={24} name="onboarding"/>
          </IconContainer>
          &nbsp;Wizard</CustomMenuItem>
        <CustomMenuItem>
          <NavLink
            onClick={handleClose}
            to={'/support/contact'}>
            <IconContainer>
              <Icon size={24} name="support"/>
            </IconContainer>
            &nbsp;
            {t("header8", "Kontaktieren Sie Support")}
          </NavLink>
        </CustomMenuItem>
        {/*<MenuItem onClick={handleClose}>{t("header8", "Kontaktieren Sie Support")}</MenuItem>*/}
      </Menu>
    </div>
  );
}
