import React from 'react';
import styled from '@emotion/styled';
import {buttonmain, buttonmainhover, blue, bluedarker, darkblue, grey30, orange1, white} from '../colors';
import Icon from './Icon/Icon';

const ButtonComponent = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${props => props.small ? '54px' : props.tiny ? '32px' : '56px'};
  padding: ${props => props.text || props.tiny ? '16px 24px 16px 24px' : '11px'};
  ${props => !props.text && 'width: 54px;' };

  line-height: ${props => props.small ? '54px' : props.tiny ? '32px' : '56px'};

  background: ${props => {
  if (props.disabled) {
    return grey30;
  }
  if (props.delete || props.danger) {
    return orange1;
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  return props.outline ? 'transparent' : blue;
}};

  color: ${props => {
  if (props.outline) {
    return darkblue
  }
  return white
}};

  border: 3px solid ${props => {
  if (props.disabled) {
    return grey30;
  }
  if (props.delete || props.danger) {
    return orange1;
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  return blue;
}};

  appearance: none;
  cursor: pointer;
  border-radius: ${props => props.rounded ? '100px' : '4px'};
  letter-spacing: 0.75px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
  font-size: 15px;
  text-transform: ${props => props.texttransformnone ? '' : 'uppercase'};
  text-decoration: none;
  text-align: left;
  padding: ${props => props.text ? '18px 28px 16px 24px' : '11px'};

  ${props => !props.text && 'width: 54px;' };

  line-height: ${props => props.small ? '54px' : '56px'};

  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  ${props => !props.disabled && !props.delete && !props.danger &&`
  &:hover {
    color: ${white};
    background: ${bluedarker};
    border-color ${bluedarker};
  };
  `};
   
    ${props => props.mainbutton && !props.disabled && `
      &:hover {
        color: ${white};
        background: ${buttonmainhover};
        border-color ${buttonmainhover};
      };
  `};

  > svg {
    ${props => props.text && props.icon && 'margin-right: 16px;'}
  }
`;

export const ButtonText = styled.div`
  margin-bottom: -4px;
  line-height: 20px;
`;

export class Button extends React.PureComponent {

  render() {
    const { right, type, texttransformnone, minwidth, tiny, rounded} = this.props;

    return (
      <ButtonComponent
        {...this.props}
        text={this.props.children !== undefined ? "true" : undefined}
        mainbutton
        right={right}
        type={type}
        texttransformnone={texttransformnone}
        minwidth={minwidth}
        tiny={tiny}
        rounded={rounded}>

        {this.props.icon && !right && <Icon name={this.props.icon} size={this.props.iconsize} />}

        <ButtonText>
          {this.props.children}
        </ButtonText>

        {this.props.icon && right && <Icon name={this.props.icon} size={this.props.iconsize} />}

      </ButtonComponent>
    );
  }
}
