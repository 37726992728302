import React from "react";
import styled from "@emotion/styled";

export const HeadlineContainer = styled.div`
    display: ${props => props.noFlex ? "" : "flex"};
    align-items: center;
    padding: ${props => props.noMargin ? "0" : "0 70px"};
`;

export const HeadlineText = styled.h1`
    margin-right: 84px;
`;

export default class Headline extends React.PureComponent {
  render() {
      const { noMargin = false } = this.props;
    return (
      <HeadlineContainer noMargin={noMargin}>
        <HeadlineText>
          {this.props.children}
        </HeadlineText>
      </HeadlineContainer>
    );
  }
}
