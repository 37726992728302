import React from 'react';

import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import { Text } from './PasswordRecoverySuccess';
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";

class RegistrationSuccess extends React.PureComponent {
    render() {
        const { t } = this.props;
        return (
            <Page background={grey5}>
                <PageContent>

                    <Headline>{t('registrsuccess1',"Deepsight Portal")}</Headline>

                    <Text>
                        <p>{t('registrsuccess2',"Herzlichen Glückwunsch!")}</p>
                        <p>{t('registrsuccess3',"Sie haben sich erfolgreich registriert. Jetzt müssen Sie nur noch Ihre E-Mail verifizieren.")}</p>
                        <p>{t('registrsuccess4',"Wir haben Ihnen soeben eine E-Mail gesendet. Verwenden Sie bitte den Verifizierungslink in der E-Mail, um die Registrierung abzuschließen.")}</p>
                    </Text>

                </PageContent>
            </Page>
        );
    }
}

export default withTranslation("translations")(RegistrationSuccess);
