import React from 'react';

import PageContent from '../components/PageContent';
import Headline from '../components/Headline';
import styled from '@emotion/styled';
import Page from '../components/Page';
import { grey5 } from '../colors';
import { withTranslation } from "react-i18next";

export const Text = styled.div`
                        padding-left: 70px
                    `;

class PasswordRecoverySuccess extends React.PureComponent {
    render() {
        const email = this.props.match.params.email;
        const { t } = this.props;
        return (
            <Page background={grey5}>
                <PageContent>

                    <Headline>{t('passwordrecoverysuccess1',"Passwortanfrage versendet.")}</Headline>

                    <Text>
                        <p>{t('passwordrecoverysuccess2',"Sie erhalten in Kürze eine E-Mail mit einem neuen Passwort an Ihre hinterlegte Adresse")} {email}</p>
                    </Text>

                </PageContent>
            </Page>
        );
    }
}

export default withTranslation("translations")(PasswordRecoverySuccess);
